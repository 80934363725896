import {
  createAjaxWithCredentials,
  createAjaxWithoutCredentials,
} from '../../../../client/app/_shared/utils/ajax.ts';
import { buildUrlQueryString } from '../../../../client/app/_shared/utils/buildUrlQueryString.ts';
import { getExistingCookies } from '../../../../client/app/_shared/utils/cookieUtils.ts';
import {
  logErrorMessageToMonitoringTool,
  logErrorToMonitoringTool,
} from '../../../../client/app/_shared/utils/logError.ts';
import { getUrlFactory } from '../../../../client/app/_shared/utils/urlFactory.ts';
import { PardotEventTypes } from '../constants/pardotEventTypes.ts';

export interface IPardotUserInfo {
  readonly email: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly companyName?: string;
  readonly phoneNumber?: string;
  readonly businessRole?: string;
  readonly businessType?: string;
  readonly country?: string;
  readonly state?: string;
}

interface IGeolocationResponseModel {
  country: string;
  regionName: string;
  status: string;
}

interface IGeolocationInfo {
  country?: string;
  state?: string;
}

const TrackingCookieNames = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_data',
  'utm_audience',
  'utm_type',
  'utm_content',
  'utm_keyword',
  'utm_term',
  'gclid',
] as const;

type TrackingCookieNamesType = (typeof TrackingCookieNames)[number];

const _getPath = (eventType: PardotEventTypes): string => {
  switch (eventType) {
    case PardotEventTypes.TrialStarted:
      return 'l/849473/2020-04-07/41sz';
    case PardotEventTypes.TrialFinished:
      return 'l/849473/2020-04-07/41t2';
    case PardotEventTypes.SignIn:
      return 'l/849473/2020-04-03/3wgn';
    case PardotEventTypes.SignUpLearnPortal:
      return 'l/849473/2022-12-12/f1wcs';
  }
};

export const logEventToPardot = async (
  eventType: PardotEventTypes,
  userInfo: IPardotUserInfo,
): Promise<void> => {
  if (!self._envConfig.isPardotEnabled) {
    return;
  }

  const path = _getPath(eventType);
  const url = getUrlFactory().getPardotUrl();
  if (!url) {
    logErrorMessageToMonitoringTool('logEventToPardot: Failed to get url for sending event.');
    return;
  }

  const geolocationFromResponse = await fetchGeolocationInfo();
  const geolocationQueryInfo = getGeolocationInfo(geolocationFromResponse);
  const trackingCookies = getTrackingCookies(document.cookie);

  try {
    const ajax = createAjaxWithCredentials();
    const query = buildUrlQueryString({
      ...userInfo,
      ...geolocationQueryInfo,
      ...Object.fromEntries(trackingCookies),
    });
    await ajax.request('GET', `${url}/${path}${query}`, null);
  } catch (e) {
    logErrorToMonitoringTool('logEventToPardot.ts', e);
  }
};

const fetchGeolocationInfo = async (): Promise<IGeolocationResponseModel> => {
  const ajax = createAjaxWithoutCredentials();
  return ajax
    .request(
      'GET',
      'https://pro.ip-api.com/json/?key=XMfm2lAgNcbxcSV&fields=status,country,regionName&lang=en',
      null,
    )
    .then((r) => {
      if (r.status !== 200) {
        return null;
      }

      return parseResponse(r);
    })
    .catch((e) => {
      logErrorToMonitoringTool('fetchGeolocationInfo: Failed to fetch geolocation from API.', e);
      return null;
    });
};

const getGeolocationInfo = (data: IGeolocationResponseModel | null): IGeolocationInfo => {
  const countriesWithRegion = ['australia', 'canada', 'united states'];
  if (!data || data.status.toLowerCase() !== 'success') {
    return {};
  }

  if (countriesWithRegion.includes(data.country.toLowerCase())) {
    return {
      country: data.country,
      state: data.regionName,
    };
  }

  return { country: data.country };
};

const parseResponse = (response: XMLHttpRequest | null) => {
  if (!response || !response.responseText) {
    return null;
  }

  return JSON.parse(response.responseText);
};

const getTrackingCookies = (
  documentCookie: string,
): ReadonlyMap<TrackingCookieNamesType, string> => {
  const cookies = getExistingCookies(documentCookie);
  return new Map(
    TrackingCookieNames.filter((cookieName) => Object.hasOwn(cookies, cookieName)).map(
      (cookieName: TrackingCookieNamesType) => [cookieName, cookies[cookieName] ?? ''],
    ),
  );
};
